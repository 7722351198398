// Check Icon:

import React from 'react'

// ___________________________________________________________________

const Check = () => (
  <svg width="100%" viewBox="0 0 32 32">
    <path
      d="M27.375.25l-2.063 2.188-14.375 15.25-4.313-4.313-2.125-2.125-4.25 4.25 2.125 2.125 6.5 6.5 2.188 2.188 2.125-2.25 16.5-17.5 2.063-2.188-4.375-4.125z"
      transform="translate(0 2)"
    />
  </svg>
)

export default Check
