// PDF Icon:

// Core
import React from 'react'

// ___________________________________________________________________

const Pdf = () => (
  <svg width="100%" viewBox="0 0 32 32">
    <path
      d="M.25 0c-.138 0-.25.113-.25.25v15.75h26v-8h-7.75c-.138 0-.25-.113-.25-.25v-7.75h-17.75zm18.75 0v7h7l-7-7zm-16.25 19.188v5.813h1.5v-1.906h.938c.424 0 .779-.039 1.063-.156.286-.117.518-.302.75-.531.169-.169.315-.378.406-.625.091-.25.125-.49.125-.75 0-.336-.063-.635-.188-.875-.122-.24-.297-.44-.531-.594-.195-.128-.401-.219-.656-.281-.253-.065-.568-.094-.938-.094h-2.469zm5.844 0v5.813h2.063c.464 0 .849-.026 1.188-.094.339-.068.654-.203.938-.375.378-.229.69-.542.938-.969.247-.43.375-.927.375-1.469 0-.562-.112-1.055-.344-1.469-.229-.417-.536-.755-.938-1-.234-.146-.531-.234-.875-.313-.344-.081-.745-.125-1.219-.125h-2.125zm6.656 0v5.813h1.5v-2.5h2.469v-1.125h-2.469v-1.063h2.656v-1.125h-4.156zm-11 1.094h.25c.247 0 .445-.008.594 0 .148.005.312.031.469.094.117.047.224.13.313.25.089.117.125.273.125.438 0 .167-.018.289-.063.406-.042.115-.122.229-.219.313-.112.094-.24.156-.406.188-.164.031-.37.031-.625.031h-.438v-1.719zm5.844 0h.375c.323 0 .56.003.719.031.161.026.346.081.5.156.302.154.518.352.656.625.141.271.219.617.219 1 0 .385-.091.706-.219.969-.128.26-.32.469-.594.625-.159.086-.333.154-.5.188-.167.031-.424.031-.781.031h-.375v-3.625zm-10.094 7.719v3.7510000000000003c0 .138.112.25.25.25h25.5c.138 0 .25-.112.25-.25v-3.7510000000000003h-26z"
      transform="translate(3)"
    />
  </svg>
)

export default Pdf
