// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-products-eon-multi-surface-disinfectant-tsx": () => import("./../../../src/pages/products/eon-multi-surface-disinfectant.tsx" /* webpackChunkName: "component---src-pages-products-eon-multi-surface-disinfectant-tsx" */),
  "component---src-pages-q-2-tsx": () => import("./../../../src/pages/q2.tsx" /* webpackChunkName: "component---src-pages-q-2-tsx" */),
  "component---src-pages-q-3-tsx": () => import("./../../../src/pages/q3.tsx" /* webpackChunkName: "component---src-pages-q-3-tsx" */),
  "component---src-pages-returns-tsx": () => import("./../../../src/pages/returns.tsx" /* webpackChunkName: "component---src-pages-returns-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-hand-sanitizer-index-tsx": () => import("./../../../src/templates/HandSanitizer/index.tsx" /* webpackChunkName: "component---src-templates-hand-sanitizer-index-tsx" */),
  "component---src-templates-post-article-index-tsx": () => import("./../../../src/templates/Post/Article/index.tsx" /* webpackChunkName: "component---src-templates-post-article-index-tsx" */)
}

