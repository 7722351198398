// eOn Hand Sanitizer Can

// ___________________________________________________________________

import * as React from 'react'
import styled from 'styled-components'
import theme from '../../gatsby-plugin-theme-ui'

// ___________________________________________________________________

const CanHand = () => (
  <SVG viewBox="0 0 174.83 508.71">
    <title>eOn Hand Sanitizer Can icon</title>
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="87.42"
        y1="231.65"
        x2="87.42"
        y2="519.71"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.38" stopColor="#fff" />
        <stop offset="0.79" stopColor="#b8d8ff" />
      </linearGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M142.25,87.05l-.81.63c2.48,2.57,7.51,3.91,12.74,9.2a90.87,90.87,0,0,1,15.66,29.82c3.71,12.15,3.23,28.09,3.23,43.5s.17,30.82.25,46.23V457.75c0,11,1.32,24.33-3.23,30.82a20.33,20.33,0,0,1-5.37,5,41.85,41.85,0,0,1-10.43,5.16c-14.3,4.54-33,6.72-37.76,7.24-7,.79-11.87,1.23-16.42,1.31-5.58.1-11.58.13-13.12.11-45.78-.53-70.61-3.76-79.19-17.8C3,481.7,4.07,467.44,4.07,454.52Q4,418.61,3.82,382.7q-.87-86-1.74-172l-.75-51c0-10.54-.16-20.59,2-29.08A83.92,83.92,0,0,1,16.5,101.6C19.55,97.41,27.08,90.88,30.44,88"
        />
        <g className="cls-2">
          <path
            className="cls-3"
            d="M30.17,88.17c-1.92-.37-3.26-.28-5.18-.65-.16-6-.24-13.9,0-23.08.34-13.38.55-20.24,2.15-28.77a125.86,125.86,0,0,1,6.53-22.6c2.16-5.48,4.4-7.73,6.27-11.2,21.47-.51,43.32-.08,65.56,0,8.26,0,16.46-.12,24.61,0,.53.63,7,9,8,12.22,2.9,8.69,4.35,13,5.74,20,1.95,9.79,2.35,17.17,2.83,26.52.33,6.65.55,15.35.17,25.61l-5.06,1.32"
          />
          <line className="cls-4" x1="143.05" y1="87.48" x2="25.8" y2="87.24" />
          <line
            className="cls-4"
            x1="132.86"
            y1="90.87"
            x2="40.36"
            y2="90.69"
          />
        </g>
        <path
          className="cls-5"
          d="M30.17,88.17c-1.92-.37-3.26-.28-5.18-.65-.16-6-.24-13.9,0-23.08.34-13.38.55-20.24,2.15-28.77a125.86,125.86,0,0,1,6.53-22.6c2.16-5.48,4.4-7.73,6.27-11.2,21.47-.51,43.32-.08,65.56,0,8.26,0,16.46-.12,24.61,0,.53.63,7,9,8,12.22,2.9,8.69,4.35,13,5.74,20,1.95,9.79,2.35,17.17,2.83,26.52.33,6.65.55,15.35.17,25.61l-5.06,1.32"
        />
        <line className="cls-6" x1="143.05" y1="87.48" x2="25.8" y2="87.24" />
        <line className="cls-6" x1="132.86" y1="90.87" x2="40.36" y2="90.69" />
        <path
          className="cls-7"
          d="M138,76.05l-.55,9.7q-25.32.84-52.17.87-26.62,0-51.75-.87l-1.32-9.26Z"
        />
        <path
          className="cls-7"
          d="M85.25,65.44c8,.26,16.51.28,25.5,0s17.22-.93,25.07-1.73a8.22,8.22,0,0,1,3.41,3.2c1.3,2.26,1.67,5.58.05,7.89A5.85,5.85,0,0,1,138,76.05L85.25,77.17"
        />
        <path className="cls-8" d="M141.33,54.06a95.36,95.36,0,0,1,.52,14" />
        <path
          className="cls-7"
          d="M87.36,65.44c-8,.26-16.51.28-25.49,0s-17.23-.93-25.08-1.73a8.22,8.22,0,0,0-3.41,3.2c-1.3,2.26-1.67,5.58-.05,7.89a5.85,5.85,0,0,0,1.24,1.28l52.79,1.12"
        />
        <path className="cls-8" d="M31.28,54.06a96.77,96.77,0,0,0-.52,14" />
        <path
          className="cls-9"
          d="M30.43,75.8q12.8.7,26.23,1.06,15.77.42,30.7.31"
        />
        <path
          className="cls-10"
          d="M142.18,53.36q-12.8.7-26.23,1.07-15.76.42-30.7.3"
        />
        <path
          className="cls-10"
          d="M30.43,53.36q12.8.7,26.23,1.07,15.77.42,30.7.3"
        />
        <path
          className="cls-10"
          d="M136.93,43.62H43.15a98.37,98.37,0,0,1,1-12.46A101.75,101.75,0,0,1,52.93,2"
        />
        <path className="cls-10" d="M38.59,43.62A101,101,0,0,1,48.37,2" />
        <line className="cls-10" x1="130.29" y1="35.73" x2="52.97" y2="35.73" />
        <path className="cls-10" d="M34.3,43.62A101,101,0,0,1,44.08,2" />
        <rect
          className="cls-10"
          x="109.16"
          y="37.07"
          width="2.74"
          height="10.37"
          rx="0.44"
          transform="translate(68.28 152.79) rotate(-90)"
        />
        <rect
          className="cls-10"
          x="60.7"
          y="37.07"
          width="2.74"
          height="10.37"
          rx="0.44"
          transform="translate(19.82 104.33) rotate(-90)"
        />
        <path
          className="cls-11"
          d="M142.18,75.8q-12.8.7-26.23,1.06-15.76.42-30.7.31"
        />
        <path
          className="cls-12"
          d="M57.18,65.21c-7.1-.34-13.9-.87-20.39-1.53Q43,63,49.47,62.52c12.89-1,25.2-1.29,36.84-1.13.35,0,23.55.13,36.83,1.13q6.47.49,12.68,1.16c-6.49.66-13.29,1.19-20.38,1.53C104.77,65.72,67.84,65.72,57.18,65.21Z"
        />
        <rect
          x="116.56"
          y="54.51"
          width="2.74"
          height="9.2"
          rx="0.42"
          transform="translate(235.87 118.22) rotate(-180)"
        />
        <rect
          x="112.05"
          y="54.8"
          width="2.74"
          height="9.2"
          rx="0.42"
          transform="translate(226.85 118.81) rotate(-180)"
        />
        <rect
          x="103.9"
          y="54.8"
          width="2.74"
          height="9.2"
          rx="0.42"
          transform="translate(210.55 118.81) rotate(-180)"
        />
        <rect x="53.3" y="54.51" width="2.74" height="9.2" rx="0.42" />
        <rect x="57.81" y="54.8" width="2.74" height="9.2" rx="0.42" />
        <rect x="65.96" y="54.8" width="2.74" height="9.2" rx="0.42" />
        <rect x="79.74" y="54.8" width="12.91" height="9.2" rx="0.91" />
        <path
          className="cls-11"
          d="M142.18,81.09q-12.8.7-26.23,1.07-15.76.42-30.7.3"
        />
        <path
          className="cls-9"
          d="M30.43,81.09q12.8.7,26.23,1.07,15.77.42,30.7.3"
        />
        <rect x="86.15" y="293.13" width="0.4" height="0.4" rx="0.16" />
        <path
          className="cls-13"
          d="M83.72,334.75h37.55a36.4,36.4,0,0,0,36.39-36.4V260.81"
        />
        <path
          className="cls-14"
          d="M147.12,260.84v34.43a33.37,33.37,0,0,1-33.38,33.38H79.31"
        />
        <path
          className="cls-15"
          d="M136.58,260.88v31.31a30.37,30.37,0,0,1-30.36,30.37H74.9"
        />
        <path
          className="cls-16"
          d="M126,260.91v28.2a27.34,27.34,0,0,1-27.35,27.35H70.49"
        />
        <path
          className="cls-17"
          d="M115.51,260.94V286a24.34,24.34,0,0,1-24.34,24.34H66.08"
        />
        <path
          className="cls-18"
          d="M105,261v22a21.33,21.33,0,0,1-21.32,21.32h-22"
        />
        <path
          className="cls-19"
          d="M94.43,261v18.87a18.3,18.3,0,0,1-18.31,18.3H57.26"
        />
        <path
          className="cls-20"
          d="M83.89,261.05V276.8A15.3,15.3,0,0,1,68.6,292.09H52.85"
        />
        <path
          className="cls-21"
          d="M73.35,261.08v12.64A12.27,12.27,0,0,1,61.07,286H48.44"
        />
        <path
          className="cls-22"
          d="M62.81,261.11v9.53a9.26,9.26,0,0,1-9.26,9.26H44"
        />
        <path
          className="cls-23"
          d="M52.27,261.15v6.41A6.24,6.24,0,0,1,46,273.81H39.62"
        />
        <path
          className="cls-24"
          d="M41.74,261.18v3.3a3.24,3.24,0,0,1-3.24,3.23H35.2"
        />
        <path
          className="cls-25"
          d="M31.2,261.21v.19a.22.22,0,0,1-.22.22h-.19"
        />
        <path d="M46.45,164.92a17.24,17.24,0,0,0-9.15-2.39A17.71,17.71,0,0,0,27.93,165a17.39,17.39,0,0,0-6.38,6.79,20.74,20.74,0,0,0-2.31,9.91,19.86,19.86,0,0,0,2.44,9.89,17.74,17.74,0,0,0,6.76,6.8A20,20,0,0,0,45,199.58a18.81,18.81,0,0,0,5.78-3.2,2.54,2.54,0,0,0,1-2.09,3.12,3.12,0,0,0-1.13-2.19,2.89,2.89,0,0,0-2-.63,3.35,3.35,0,0,0-2,.79,11.26,11.26,0,0,1-3.7,2,14.28,14.28,0,0,1-4.63.8,13.13,13.13,0,0,1-6.75-1.72,12.53,12.53,0,0,1-4.61-4.7A12.84,12.84,0,0,1,25.42,184H51.63a3,3,0,0,0,2.15-.8,2.82,2.82,0,0,0,.85-2.14,20.83,20.83,0,0,0-2.13-9.54A16.16,16.16,0,0,0,46.45,164.92Zm2.08,13.6h-23a13.53,13.53,0,0,1,1.22-3.75A11.35,11.35,0,0,1,31,170.05a11.63,11.63,0,0,1,6.33-1.71A11.05,11.05,0,0,1,43.4,170a10.93,10.93,0,0,1,4,4.54A14,14,0,0,1,48.53,178.52Z" />
        <path d="M156,170.5a15,15,0,0,0-6.14-6,18.56,18.56,0,0,0-8.74-2,17.11,17.11,0,0,0-8.15,2,15.93,15.93,0,0,0-3.36,2.41v-.77a3.16,3.16,0,0,0-.88-2.33,3.11,3.11,0,0,0-2.27-.88,3,3,0,0,0-3.2,3.21v31.31a3,3,0,0,0,.88,2.26,3.15,3.15,0,0,0,2.32.88,3.11,3.11,0,0,0,2.27-.88,3.08,3.08,0,0,0,.88-2.26V177.66a7.92,7.92,0,0,1,1.42-4.65,9.86,9.86,0,0,1,3.92-3.26,12.89,12.89,0,0,1,5.64-1.21,13.06,13.06,0,0,1,5.82,1.27,9.44,9.44,0,0,1,4,3.76,12.8,12.8,0,0,1,1.48,6.49v17.32a3.08,3.08,0,0,0,.91,2.23,3.16,3.16,0,0,0,2.3.91,3,3,0,0,0,2.23-.91,3.08,3.08,0,0,0,.91-2.23V180.06A19.07,19.07,0,0,0,156,170.5Z" />
        <path d="M105.67,155.13a3,3,0,0,0-2.12,5.12,21.49,21.49,0,1,1-15.26-6.31h0l-.06,8.86,11.9-11.53L88.43,139.54,88.38,148h-.11A27.52,27.52,0,1,0,107.78,156,3,3,0,0,0,105.67,155.13Z" />
        <line className="cls-26" x1="20.79" y1="360.95" x2="113.45" y2="361" />
        <line
          className="cls-26"
          x1="20.79"
          y1="377.24"
          x2="105.33"
          y2="377.28"
        />
        <line
          className="cls-26"
          x1="20.78"
          y1="393.53"
          x2="105.32"
          y2="393.57"
        />
        <rect
          className="cls-27"
          x="20.57"
          y="415.16"
          width="86.62"
          height="38.19"
        />
        <rect
          className="cls-27"
          x="125.47"
          y="470.67"
          width="30.91"
          height="7.65"
        />
        <line
          className="cls-28"
          x1="21.49"
          y1="262.56"
          x2="27.59"
          y2="262.56"
        />
        <line
          className="cls-28"
          x1="21.48"
          y1="281.14"
          x2="40.93"
          y2="281.15"
        />
        <line
          className="cls-28"
          x1="21.47"
          y1="299.73"
          x2="54.27"
          y2="299.74"
        />
        <line
          className="cls-28"
          x1="21.47"
          y1="318.31"
          x2="67.61"
          y2="318.33"
        />
        <line
          className="cls-28"
          x1="21.46"
          y1="336.89"
          x2="80.95"
          y2="336.92"
        />
        <path d="M43.81,276.52H41a1.4,1.4,0,1,1,0-2.79h2.79a1.4,1.4,0,0,1,0,2.79Z" />
        <path d="M65.27,288.9H62.48a1.4,1.4,0,0,1,0-2.8h2.79a1.4,1.4,0,1,1,0,2.79Z" />
        <path d="M86.74,301.27h-2.8a1.4,1.4,0,0,1,0-2.79h2.79a1.4,1.4,0,0,1,0,2.79Zm-23.27,0H60.68a1.4,1.4,0,1,1,0-2.79h2.79a1.4,1.4,0,1,1,0,2.79Z" />
        <path d="M108.2,313.65h-2.79a1.4,1.4,0,1,1,0-2.79h2.79a1.4,1.4,0,1,1,0,2.79Zm-23.27,0H82.14a1.4,1.4,0,0,1,0-2.8h2.79a1.39,1.39,0,0,1,1.4,1.39A1.4,1.4,0,0,1,84.93,313.64Z" />
        <path d="M129.67,326h-2.8a1.4,1.4,0,0,1,0-2.79h2.8a1.4,1.4,0,0,1,0,2.8Zm-23.27,0h-2.79a1.39,1.39,0,0,1-1.4-1.39,1.4,1.4,0,0,1,1.4-1.4h2.79a1.4,1.4,0,0,1,0,2.79Z" />
        <path d="M151.13,338.4h-2.79a1.4,1.4,0,1,1,0-2.79h2.79a1.4,1.4,0,1,1,0,2.79Zm-23.27,0h-2.79a1.4,1.4,0,1,1,0-2.79h2.79a1.4,1.4,0,1,1,0,2.79Zm-23.26,0h-2.8a1.4,1.4,0,0,1-1.39-1.4,1.38,1.38,0,0,1,1.39-1.39h2.8a1.4,1.4,0,0,1,0,2.79Z" />
        <rect x="21.49" y="261.81" width="2.26" height="1.5" rx="0.75" />
        <line
          className="cls-26"
          x1="21.47"
          y1="215.44"
          x2="106.01"
          y2="215.48"
        />
        <line className="cls-26" x1="21.46" y1="231.73" x2="106" y2="231.77" />
      </g>
    </g>
  </SVG>
)

export default CanHand

const SVG = styled.svg`
  .cls-1,
  .cls-10,
  .cls-28,
  .cls-3,
  .cls-5,
  .cls-6,
  .cls-8 {
    stroke: #000;
  }

  .cls-1,
  .cls-10,
  .cls-11,
  .cls-13,
  .cls-14,
  .cls-15,
  .cls-16,
  .cls-17,
  .cls-18,
  .cls-19,
  .cls-20,
  .cls-21,
  .cls-22,
  .cls-23,
  .cls-24,
  .cls-25,
  .cls-26,
  .cls-28,
  .cls-3,
  .cls-5,
  .cls-6,
  .cls-9 {
    stroke-miterlimit: 10;
  }

  .cls-1 {
    stroke-width: 2.66px;
    fill: url(#linear-gradient);
  }

  .cls-1,
  .cls-3,
  .cls-5 {
    fill-rule: evenodd;
  }

  .cls-2,
  .cls-3 {
    opacity: 0.5;
  }

  .cls-3,
  .cls-4 {
    fill: #dedede;
  }

  .cls-3 {
    stroke-width: 3.25px;
  }

  .cls-10,
  .cls-11,
  .cls-13,
  .cls-14,
  .cls-15,
  .cls-16,
  .cls-17,
  .cls-18,
  .cls-19,
  .cls-20,
  .cls-21,
  .cls-22,
  .cls-23,
  .cls-24,
  .cls-25,
  .cls-26,
  .cls-5,
  .cls-6,
  .cls-8,
  .cls-9 {
    fill: none;
  }

  .cls-5 {
    stroke-width: 2.66px;
    opacity: 0.8;
  }

  .cls-6 {
    stroke-width: 3.79px;
  }

  .cls-7 {
    fill: #666;
  }

  .cls-10,
  .cls-11,
  .cls-12,
  .cls-8,
  .cls-9 {
    stroke-linecap: square;
    stroke-width: 2.03px;
  }

  .cls-12,
  .cls-8 {
    stroke-linejoin: bevel;
  }

  .cls-13,
  .cls-9 {
    stroke: #fff;
  }

  .cls-11,
  .cls-12 {
    stroke: #f1f1f1;
  }

  .cls-13,
  .cls-14,
  .cls-15,
  .cls-16,
  .cls-17,
  .cls-18,
  .cls-19,
  .cls-20,
  .cls-21,
  .cls-22,
  .cls-23,
  .cls-24,
  .cls-25 {
    stroke-linecap: round;
  }

  .cls-13,
  .cls-14,
  .cls-15,
  .cls-17,
  .cls-18,
  .cls-19,
  .cls-20,
  .cls-21,
  .cls-22,
  .cls-23,
  .cls-24,
  .cls-25 {
    stroke-width: 3.28px;
  }

  .cls-14 {
    stroke: #eaf3fe;
  }

  .cls-15 {
    stroke: #d4e7fc;
  }

  .cls-16 {
    stroke: #bfdbfb;
    stroke-width: 3.28px;
  }

  .cls-17 {
    stroke: #aacffa;
  }

  .cls-18 {
    stroke: #95c3f9;
  }

  .cls-19 {
    stroke: #7fb7f8;
  }

  .cls-20 {
    stroke: #6aacf6;
  }

  .cls-21 {
    stroke: #55a0f5;
  }

  .cls-22 {
    stroke: #4094f4;
  }

  .cls-23 {
    stroke: #2b88f3;
  }

  .cls-24 {
    stroke: #157cf1;
  }

  .cls-25 {
    stroke: #0070f0;
  }

  .cls-26 {
    stroke: #9c9c9b;
  }

  .cls-26,
  .cls-28 {
    stroke-width: 4.3px;
  }

  .cls-27 {
    fill: #e4e4e4;
  }
`
