// Portal Styles:

// ___________________________________________________________________

import styled from 'styled-components'
import theme from '../../gatsby-plugin-theme-ui'

// Begin Styles
// ___________________________________________________________________

export const Portal = styled.div``