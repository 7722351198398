// eOn Multi Surface Can

// ___________________________________________________________________

import * as React from 'react'
import styled from 'styled-components'
import theme from '../../gatsby-plugin-theme-ui'

// ___________________________________________________________________

const CanMulti = () => (
  <SVG viewBox="0 0 174.83 508.71">
    <title>eOn Multi Surface Can icon</title>
    <path
      className="cls-1"
      d="M142.25,87.05l-.81.63c2.48,2.57,7.51,3.91,12.74,9.2a90.87,90.87,0,0,1,15.66,29.82c3.71,12.15,3.23,28.09,3.23,43.5s.17,30.82.25,46.23V457.75c0,11,1.32,24.33-3.23,30.82a20.33,20.33,0,0,1-5.37,5,41.85,41.85,0,0,1-10.43,5.16c-14.3,4.54-33,6.72-37.76,7.24-7,.79-11.87,1.23-16.42,1.31-5.58.1-11.58.13-13.12.11-45.78-.53-70.61-3.76-79.19-17.8C3,481.7,4.07,467.44,4.07,454.52Q4,418.61,3.82,382.7q-.87-86-1.74-172l-.75-51c0-10.54-.16-20.59,2-29.08A83.92,83.92,0,0,1,16.5,101.6C19.55,97.41,27.08,90.88,30.44,88"
    />
    <g className="cls-2">
      <path
        className="cls-3"
        d="M30.17,88.17c-1.92-.37-3.26-.28-5.18-.65-.16-6-.24-13.9,0-23.08.34-13.38.55-20.24,2.15-28.77a125.86,125.86,0,0,1,6.53-22.6c2.16-5.48,4.4-7.73,6.27-11.2,21.47-.51,43.32-.08,65.56,0,8.26,0,16.46-.12,24.61,0,.53.63,7,9,8,12.22,2.9,8.69,4.35,13,5.74,20,1.95,9.79,2.35,17.17,2.83,26.52.33,6.65.55,15.35.17,25.61l-5.06,1.32"
      />
      <line className="cls-4" x1="143.05" y1="87.48" x2="25.8" y2="87.24" />
      <line className="cls-4" x1="132.86" y1="90.87" x2="40.36" y2="90.69" />
    </g>
    <path
      className="cls-5"
      d="M30.17,88.17c-1.92-.37-3.26-.28-5.18-.65-.16-6-.24-13.9,0-23.08.34-13.38.55-20.24,2.15-28.77a125.86,125.86,0,0,1,6.53-22.6c2.16-5.48,4.4-7.73,6.27-11.2,21.47-.51,43.32-.08,65.56,0,8.26,0,16.46-.12,24.61,0,.53.63,7,9,8,12.22,2.9,8.69,4.35,13,5.74,20,1.95,9.79,2.35,17.17,2.83,26.52.33,6.65.55,15.35.17,25.61l-5.06,1.32"
    />
    <line className="cls-6" x1="143.05" y1="87.48" x2="25.8" y2="87.24" />
    <line className="cls-6" x1="132.86" y1="90.87" x2="40.36" y2="90.69" />
    <path
      className="cls-7"
      d="M138,76.05l-.55,9.7q-25.32.84-52.17.87-26.62,0-51.75-.87l-1.32-9.26Z"
    />
    <path
      className="cls-7"
      d="M85.25,65.44c8,.26,16.51.28,25.5,0s17.22-.93,25.07-1.73a8.22,8.22,0,0,1,3.41,3.2c1.3,2.26,1.67,5.58.05,7.89A5.85,5.85,0,0,1,138,76.05L85.25,77.17"
    />
    <path className="cls-8" d="M141.33,54.06a95.36,95.36,0,0,1,.52,14" />
    <path
      className="cls-7"
      d="M87.36,65.44c-8,.26-16.51.28-25.49,0s-17.23-.93-25.08-1.73a8.22,8.22,0,0,0-3.41,3.2c-1.3,2.26-1.67,5.58-.05,7.89a5.85,5.85,0,0,0,1.24,1.28l52.79,1.12"
    />
    <path className="cls-8" d="M31.28,54.06a96.77,96.77,0,0,0-.52,14" />
    <path className="cls-9" d="M30.43,75.8q12.8.7,26.23,1.06,15.77.42,30.7.31" />
    <path className="cls-10" d="M142.18,53.36q-12.8.7-26.23,1.07-15.76.42-30.7.3" />
    <path className="cls-10" d="M30.43,53.36q12.8.7,26.23,1.07,15.77.42,30.7.3" />
    <path
      className="cls-10"
      d="M136.93,43.62H43.15a98.37,98.37,0,0,1,1-12.46A101.75,101.75,0,0,1,52.93,2"
    />
    <path className="cls-10" d="M38.59,43.62A101,101,0,0,1,48.37,2" />
    <line className="cls-10" x1="130.29" y1="35.73" x2="52.97" y2="35.73" />
    <path className="cls-10" d="M34.3,43.62A101,101,0,0,1,44.08,2" />
    <rect
      className="cls-10"
      x="109.16"
      y="37.07"
      width="2.74"
      height="10.37"
      rx="0.44"
      transform="translate(68.28 152.79) rotate(-90)"
    />
    <rect
      className="cls-10"
      x="60.7"
      y="37.07"
      width="2.74"
      height="10.37"
      rx="0.44"
      transform="translate(19.82 104.33) rotate(-90)"
    />
    <path className="cls-11" d="M142.18,75.8q-12.8.7-26.23,1.06-15.76.42-30.7.31" />
    <path
      className="cls-12"
      d="M57.18,65.21c-7.1-.34-13.9-.87-20.39-1.53Q43,63,49.47,62.52c12.89-1,25.2-1.29,36.84-1.13.35,0,23.55.13,36.83,1.13q6.47.49,12.68,1.16c-6.49.66-13.29,1.19-20.38,1.53C104.77,65.72,67.84,65.72,57.18,65.21Z"
    />
    <rect
      x="116.56"
      y="54.51"
      width="2.74"
      height="9.2"
      rx="0.42"
      transform="translate(235.87 118.22) rotate(-180)"
    />
    <rect
      x="112.05"
      y="54.8"
      width="2.74"
      height="9.2"
      rx="0.42"
      transform="translate(226.85 118.81) rotate(-180)"
    />
    <rect
      x="103.9"
      y="54.8"
      width="2.74"
      height="9.2"
      rx="0.42"
      transform="translate(210.55 118.81) rotate(-180)"
    />
    <rect x="53.3" y="54.51" width="2.74" height="9.2" rx="0.42" />
    <rect x="57.81" y="54.8" width="2.74" height="9.2" rx="0.42" />
    <rect x="65.96" y="54.8" width="2.74" height="9.2" rx="0.42" />
    <rect x="79.74" y="54.8" width="12.91" height="9.2" rx="0.91" />
    <circle className="cls-13" cx="86.45" cy="19.95" r="11.88" />
    <circle cx="86.45" cy="19.95" r="6.99" />
    <circle className="cls-14" cx="86.45" cy="19.95" r="4.19" />
    <path className="cls-11" d="M142.18,81.09q-12.8.7-26.23,1.07-15.76.42-30.7.3" />
    <path className="cls-9" d="M30.43,81.09q12.8.7,26.23,1.07,15.77.42,30.7.3" />
    <rect x="86.15" y="293.13" width="0.4" height="0.4" rx="0.16" />
    <line className="cls-15" x1="83.72" y1="334.75" x2="83.72" y2="334.75" />
    <path
      className="cls-16"
      d="M91.5,334.75h29.77a36.4,36.4,0,0,0,36.39-36.4V264.7"
    />
    <line className="cls-15" x1="157.66" y1="260.81" x2="157.66" y2="260.81" />
    <line className="cls-17" x1="150.22" y1="260.83" x2="150.22" y2="260.83" />
    <path
      className="cls-18"
      d="M150.22,268.61v27.57A34.27,34.27,0,0,1,116,330.45H84.5"
    />
    <line className="cls-17" x1="80.61" y1="330.45" x2="80.61" y2="330.45" />
    <line className="cls-19" x1="142.78" y1="260.86" x2="142.78" y2="260.86" />
    <path
      className="cls-20"
      d="M142.78,268.64V294a32.14,32.14,0,0,1-32.14,32.13H81.39"
    />
    <line className="cls-19" x1="77.5" y1="326.14" x2="77.5" y2="326.14" />
    <line className="cls-21" x1="135.34" y1="260.88" x2="135.34" y2="260.88" />
    <path className="cls-22" d="M135.34,268.67v23.16a30,30,0,0,1-30,30h-27" />
    <line className="cls-21" x1="74.38" y1="321.84" x2="74.38" y2="321.84" />
    <line className="cls-23" x1="127.9" y1="260.9" x2="127.9" y2="260.9" />
    <path
      className="cls-24"
      d="M127.9,268.7v21A27.88,27.88,0,0,1,100,317.54H75.16"
    />
    <line className="cls-23" x1="71.27" y1="317.54" x2="71.27" y2="317.54" />
    <line className="cls-25" x1="120.46" y1="260.93" x2="120.46" y2="260.93" />
    <path
      className="cls-26"
      d="M120.46,268.72v18.76a25.75,25.75,0,0,1-25.75,25.76H72.05"
    />
    <line className="cls-25" x1="68.15" y1="313.24" x2="68.15" y2="313.24" />
    <line className="cls-27" x1="113.03" y1="260.95" x2="113.03" y2="260.95" />
    <path
      className="cls-28"
      d="M113,268.75v16.56A23.63,23.63,0,0,1,89.4,308.94H68.94"
    />
    <line className="cls-27" x1="65.04" y1="308.94" x2="65.04" y2="308.94" />
    <line className="cls-29" x1="105.59" y1="260.98" x2="105.59" y2="260.98" />
    <path
      className="cls-30"
      d="M105.59,268.78v14.36a21.51,21.51,0,0,1-21.5,21.5H65.83"
    />
    <line className="cls-29" x1="61.93" y1="304.64" x2="61.93" y2="304.64" />
    <line className="cls-31" x1="98.15" y1="261" x2="98.15" y2="261" />
    <path
      className="cls-32"
      d="M98.15,268.82V281a19.37,19.37,0,0,1-19.37,19.37H62.72"
    />
    <line className="cls-31" x1="58.81" y1="300.33" x2="58.81" y2="300.33" />
    <line className="cls-33" x1="90.71" y1="261.02" x2="90.71" y2="261.02" />
    <path
      className="cls-34"
      d="M90.71,268.85v9.94A17.24,17.24,0,0,1,73.47,296H59.61"
    />
    <line className="cls-33" x1="55.7" y1="296.03" x2="55.7" y2="296.03" />
    <line className="cls-35" x1="83.27" y1="261.05" x2="83.27" y2="261.05" />
    <path
      className="cls-36"
      d="M83.27,268.89v7.72a15.12,15.12,0,0,1-15.12,15.12H56.51"
    />
    <line className="cls-35" x1="52.59" y1="291.73" x2="52.59" y2="291.73" />
    <line className="cls-37" x1="75.83" y1="261.07" x2="75.83" y2="261.07" />
    <path className="cls-38" d="M75.83,268.93v5.51a13,13,0,0,1-13,13H53.4" />
    <line className="cls-37" x1="49.47" y1="287.43" x2="49.47" y2="287.43" />
    <line className="cls-39" x1="68.39" y1="261.09" x2="68.39" y2="261.09" />
    <path
      className="cls-40"
      d="M68.39,269v3.3a10.86,10.86,0,0,1-10.86,10.86H50.3"
    />
    <line className="cls-39" x1="46.36" y1="283.13" x2="46.36" y2="283.13" />
    <line className="cls-41" x1="60.95" y1="261.12" x2="60.95" y2="261.12" />
    <path className="cls-42" d="M61,269v1.06a8.73,8.73,0,0,1-8.73,8.73h-5" />
    <line className="cls-41" x1="43.25" y1="278.82" x2="43.25" y2="278.82" />
    <line className="cls-43" x1="53.51" y1="261.14" x2="53.51" y2="261.14" />
    <path className="cls-44" d="M53.41,269.11a6.61,6.61,0,0,1-6.5,5.41H44.12" />
    <line className="cls-43" x1="40.13" y1="274.52" x2="40.13" y2="274.52" />
    <line className="cls-45" x1="46.08" y1="261.17" x2="46.08" y2="261.17" />
    <path className="cls-46" d="M44.76,268.91a4.44,4.44,0,0,1-3.16,1.31h-.53" />
    <line className="cls-45" x1="37.02" y1="270.22" x2="37.02" y2="270.22" />
    <line className="cls-47" x1="38.64" y1="261.19" x2="38.64" y2="261.19" />
    <path className="cls-48" d="M38,265.23a2.33,2.33,0,0,1-1.66.69H36" />
    <line className="cls-47" x1="33.91" y1="265.92" x2="33.91" y2="265.92" />
    <line className="cls-49" x1="31.2" y1="261.21" x2="31.2" y2="261.21" />
    <line className="cls-49" x1="30.79" y1="261.62" x2="30.79" y2="261.62" />
    <path d="M46.45,164.92a17.24,17.24,0,0,0-9.15-2.39A17.71,17.71,0,0,0,27.93,165a17.39,17.39,0,0,0-6.38,6.79,20.74,20.74,0,0,0-2.31,9.91,19.86,19.86,0,0,0,2.44,9.89,17.74,17.74,0,0,0,6.76,6.8A20,20,0,0,0,45,199.58a18.81,18.81,0,0,0,5.78-3.2,2.54,2.54,0,0,0,1-2.09,3.12,3.12,0,0,0-1.13-2.19,2.89,2.89,0,0,0-2-.63,3.35,3.35,0,0,0-2,.79,11.26,11.26,0,0,1-3.7,2,14.28,14.28,0,0,1-4.63.8,13.13,13.13,0,0,1-6.75-1.72,12.53,12.53,0,0,1-4.61-4.7A12.84,12.84,0,0,1,25.42,184H51.63a3,3,0,0,0,2.15-.8,2.82,2.82,0,0,0,.85-2.14,20.83,20.83,0,0,0-2.13-9.54A16.16,16.16,0,0,0,46.45,164.92Zm2.08,13.6h-23a13.53,13.53,0,0,1,1.22-3.75A11.35,11.35,0,0,1,31,170.05a11.63,11.63,0,0,1,6.33-1.71A11.05,11.05,0,0,1,43.4,170a10.93,10.93,0,0,1,4,4.54A14,14,0,0,1,48.53,178.52Z" />
    <path d="M156,170.5a15,15,0,0,0-6.14-6,18.56,18.56,0,0,0-8.74-2,17.11,17.11,0,0,0-8.15,2,15.93,15.93,0,0,0-3.36,2.41v-.77a3.16,3.16,0,0,0-.88-2.33,3.11,3.11,0,0,0-2.27-.88,3,3,0,0,0-3.2,3.21v31.31a3,3,0,0,0,.88,2.26,3.15,3.15,0,0,0,2.32.88,3.11,3.11,0,0,0,2.27-.88,3.08,3.08,0,0,0,.88-2.26V177.66a7.92,7.92,0,0,1,1.42-4.65,9.86,9.86,0,0,1,3.92-3.26,12.89,12.89,0,0,1,5.64-1.21,13.06,13.06,0,0,1,5.82,1.27,9.44,9.44,0,0,1,4,3.76,12.8,12.8,0,0,1,1.48,6.49v17.32a3.08,3.08,0,0,0,.91,2.23,3.16,3.16,0,0,0,2.3.91,3,3,0,0,0,2.23-.91,3.08,3.08,0,0,0,.91-2.23V180.06A19.07,19.07,0,0,0,156,170.5Z" />
    <path d="M105.67,155.13a3,3,0,0,0-2.12,5.12,21.49,21.49,0,1,1-15.26-6.31h0l-.06,8.86,11.9-11.53L88.43,139.54,88.38,148h-.11A27.52,27.52,0,1,0,107.78,156,3,3,0,0,0,105.67,155.13Z" />
    <line className="cls-50" x1="20.79" y1="360.95" x2="113.45" y2="361" />
    <line className="cls-50" x1="20.79" y1="377.24" x2="105.33" y2="377.28" />
    <line className="cls-50" x1="20.78" y1="393.53" x2="105.32" y2="393.57" />
    <rect className="cls-51" x="20.57" y="415.16" width="86.62" height="38.19" />
    <rect className="cls-51" x="125.47" y="470.67" width="30.91" height="7.65" />
    <line className="cls-52" x1="21.49" y1="262.56" x2="27.59" y2="262.56" />
    <line className="cls-52" x1="21.48" y1="281.14" x2="40.93" y2="281.15" />
    <line className="cls-52" x1="21.47" y1="299.73" x2="54.27" y2="299.74" />
    <line className="cls-52" x1="21.47" y1="318.31" x2="67.61" y2="318.33" />
    <line className="cls-52" x1="21.46" y1="336.89" x2="80.95" y2="336.92" />
    <path d="M43.81,276.52H41a1.4,1.4,0,1,1,0-2.79h2.79a1.4,1.4,0,0,1,0,2.79Z" />
    <path d="M65.27,288.9H62.48a1.4,1.4,0,0,1,0-2.8h2.79a1.4,1.4,0,1,1,0,2.79Z" />
    <path d="M86.74,301.27h-2.8a1.4,1.4,0,0,1,0-2.79h2.79a1.4,1.4,0,0,1,0,2.79Zm-23.27,0H60.68a1.4,1.4,0,1,1,0-2.79h2.79a1.4,1.4,0,1,1,0,2.79Z" />
    <path d="M108.2,313.65h-2.79a1.4,1.4,0,1,1,0-2.79h2.79a1.4,1.4,0,1,1,0,2.79Zm-23.27,0H82.14a1.4,1.4,0,0,1,0-2.8h2.79a1.39,1.39,0,0,1,1.4,1.39A1.4,1.4,0,0,1,84.93,313.64Z" />
    <path d="M129.67,326h-2.8a1.4,1.4,0,0,1,0-2.79h2.8a1.4,1.4,0,0,1,0,2.8Zm-23.27,0h-2.79a1.39,1.39,0,0,1-1.4-1.39,1.4,1.4,0,0,1,1.4-1.4h2.79a1.4,1.4,0,0,1,0,2.79Z" />
    <path d="M151.13,338.4h-2.79a1.4,1.4,0,1,1,0-2.79h2.79a1.4,1.4,0,1,1,0,2.79Zm-23.27,0h-2.79a1.4,1.4,0,1,1,0-2.79h2.79a1.4,1.4,0,1,1,0,2.79Zm-23.26,0h-2.8a1.4,1.4,0,0,1-1.39-1.4,1.38,1.38,0,0,1,1.39-1.39h2.8a1.4,1.4,0,0,1,0,2.79Z" />
    <rect x="21.49" y="261.81" width="2.26" height="1.5" rx="0.75" />
    <line className="cls-50" x1="21.47" y1="215.44" x2="106.01" y2="215.48" />
    <line className="cls-50" x1="21.46" y1="231.73" x2="106" y2="231.77" />
  </SVG>
)

export default CanMulti

const SVG = styled.svg`
  .cls-1,
  .cls-14 {
    fill: #fff;
  }

  .cls-1,
  .cls-10,
  .cls-13,
  .cls-3,
  .cls-5,
  .cls-52,
  .cls-6,
  .cls-8 {
    stroke: #000;
  }

  .cls-1,
  .cls-10,
  .cls-11,
  .cls-13,
  .cls-15,
  .cls-16,
  .cls-17,
  .cls-18,
  .cls-19,
  .cls-20,
  .cls-21,
  .cls-22,
  .cls-23,
  .cls-24,
  .cls-25,
  .cls-26,
  .cls-27,
  .cls-28,
  .cls-29,
  .cls-3,
  .cls-30,
  .cls-31,
  .cls-32,
  .cls-33,
  .cls-34,
  .cls-35,
  .cls-36,
  .cls-37,
  .cls-38,
  .cls-39,
  .cls-40,
  .cls-41,
  .cls-42,
  .cls-43,
  .cls-44,
  .cls-45,
  .cls-46,
  .cls-47,
  .cls-48,
  .cls-49,
  .cls-5,
  .cls-50,
  .cls-52,
  .cls-6,
  .cls-9 {
    stroke-miterlimit: 10;
  }

  .cls-1,
  .cls-5 {
    stroke-width: 2.66px;
  }

  .cls-1,
  .cls-3,
  .cls-5 {
    fill-rule: evenodd;
  }

  .cls-2,
  .cls-3 {
    opacity: 0.5;
  }

  .cls-3,
  .cls-4 {
    fill: #dedede;
  }

  .cls-3 {
    stroke-width: 3.25px;
  }

  .cls-10,
  .cls-11,
  .cls-13,
  .cls-15,
  .cls-16,
  .cls-17,
  .cls-18,
  .cls-19,
  .cls-20,
  .cls-21,
  .cls-22,
  .cls-23,
  .cls-24,
  .cls-25,
  .cls-26,
  .cls-27,
  .cls-28,
  .cls-29,
  .cls-30,
  .cls-31,
  .cls-32,
  .cls-33,
  .cls-34,
  .cls-35,
  .cls-36,
  .cls-37,
  .cls-38,
  .cls-39,
  .cls-40,
  .cls-41,
  .cls-42,
  .cls-43,
  .cls-44,
  .cls-45,
  .cls-46,
  .cls-47,
  .cls-48,
  .cls-49,
  .cls-5,
  .cls-50,
  .cls-6,
  .cls-8,
  .cls-9 {
    fill: none;
  }

  .cls-5 {
    opacity: 0.8;
  }

  .cls-6 {
    stroke-width: 3.79px;
  }

  .cls-7 {
    fill: #666;
  }

  .cls-10,
  .cls-11,
  .cls-12,
  .cls-13,
  .cls-8,
  .cls-9 {
    stroke-linecap: square;
  }

  .cls-12,
  .cls-8 {
    stroke-linejoin: bevel;
  }

  .cls-10,
  .cls-11,
  .cls-12,
  .cls-8,
  .cls-9 {
    stroke-width: 2.03px;
  }

  .cls-15,
  .cls-16,
  .cls-9 {
    stroke: #fff;
  }

  .cls-11,
  .cls-12 {
    stroke: #f1f1f1;
  }

  .cls-13 {
    stroke-width: 0.61px;
  }

  .cls-15,
  .cls-16,
  .cls-17,
  .cls-18,
  .cls-19,
  .cls-20,
  .cls-21,
  .cls-22,
  .cls-23,
  .cls-24,
  .cls-25,
  .cls-26,
  .cls-27,
  .cls-28,
  .cls-29,
  .cls-30,
  .cls-31,
  .cls-32,
  .cls-33,
  .cls-34,
  .cls-35,
  .cls-36,
  .cls-37,
  .cls-38,
  .cls-39,
  .cls-40,
  .cls-41,
  .cls-42,
  .cls-43,
  .cls-44,
  .cls-45,
  .cls-46,
  .cls-47,
  .cls-48,
  .cls-49 {
    stroke-linecap: round;
    stroke-width: 3.28px;
  }

  .cls-16 {
    stroke-dasharray: 0 7.78;
  }

  .cls-17,
  .cls-18 {
    stroke: #f0f7fe;
  }

  .cls-18 {
    stroke-dasharray: 0 7.78;
  }

  .cls-19,
  .cls-20 {
    stroke: #e1eefd;
  }

  .cls-20 {
    stroke-dasharray: 0 7.78;
  }

  .cls-21,
  .cls-22 {
    stroke: #d2e6fc;
  }

  .cls-22 {
    stroke-dasharray: 0 7.79;
  }

  .cls-23,
  .cls-24 {
    stroke: #c3ddfb;
  }

  .cls-24 {
    stroke-dasharray: 0 7.79;
  }

  .cls-25,
  .cls-26 {
    stroke: #b4d5fb;
  }

  .cls-26 {
    stroke-dasharray: 0 7.8;
  }

  .cls-27,
  .cls-28 {
    stroke: #a5cdfa;
  }

  .cls-28 {
    stroke-dasharray: 0 7.8;
  }

  .cls-29,
  .cls-30 {
    stroke: #96c4f9;
  }

  .cls-30 {
    stroke-dasharray: 0 7.81;
  }

  .cls-31,
  .cls-32 {
    stroke: #87bcf8;
  }

  .cls-32 {
    stroke-dasharray: 0 7.82;
  }

  .cls-33,
  .cls-34 {
    stroke: #78b3f7;
  }

  .cls-34 {
    stroke-dasharray: 0 7.83;
  }

  .cls-35,
  .cls-36 {
    stroke: #69abf6;
  }

  .cls-36 {
    stroke-dasharray: 0 7.84;
  }

  .cls-37,
  .cls-38 {
    stroke: #5aa2f5;
  }

  .cls-38 {
    stroke-dasharray: 0 7.86;
  }

  .cls-39,
  .cls-40 {
    stroke: #4b9af4;
  }

  .cls-40 {
    stroke-dasharray: 0 7.88;
  }

  .cls-41,
  .cls-42 {
    stroke: #3c92f4;
  }

  .cls-42 {
    stroke-dasharray: 0 7.92;
  }

  .cls-43,
  .cls-44 {
    stroke: #2d89f3;
  }

  .cls-44 {
    stroke-dasharray: 0 7.97;
  }

  .cls-45,
  .cls-46 {
    stroke: #1e81f2;
  }

  .cls-46 {
    stroke-dasharray: 0 8.09;
  }

  .cls-47,
  .cls-48 {
    stroke: #0f78f1;
  }

  .cls-48 {
    stroke-dasharray: 0 4.22;
  }

  .cls-49 {
    stroke: #0070f0;
  }

  .cls-50 {
    stroke: #9c9c9b;
  }

  .cls-50,
  .cls-52 {
    stroke-width: 4.3px;
  }

  .cls-51 {
    fill: #e4e4e4;
  }
`
