// Check Icon:

import React from 'react'

// ___________________________________________________________________

const Envelope = () => (
  <svg height="24" viewBox="0 0 32 32">
    <path
      d="M.5 0c-.3 0-.5.2-.5.5v2c0 .3.206.587.406.688l15.094 7.625c.3.1.706.1.906 0l15.094-7.625c.3-.1.5-.388.5-.688v-2c0-.3-.2-.5-.5-.5h-31zm-.25 6.156c-.141 0-.25.119-.25.344v15c0 .3.2.5.5.5h31c.3 0 .5-.2.5-.5v-15c0-.3-.206-.412-.406-.313l-15.094 7.625c-.3.1-.706.1-.906 0l-15.188-7.625c-.05-.025-.109-.031-.156-.031z"
      transform="translate(0 5)"
    />
  </svg>
)

export default Envelope
