// Hamburger Icon:

import React from 'react'

// ___________________________________________________________________

const Hamburger = () => (
  <svg version="1.1" width="100%" viewBox="0 0 36.18 17.25">
    <line
      fill="none"
      stroke="#000000"
      strokeMiterlimit="10"
      strokeWidth="1.5px"
      y1="8.63"
      x2="36.18"
      y2="8.63"
    />
    <line
      fill="none"
      stroke="#000000"
      strokeMiterlimit="10"
      strokeWidth="1.5px"
      y1="16.63"
      x2="36.18"
      y2="16.63"
    />
    <line
      fill="none"
      stroke="#000000"
      strokeMiterlimit="10"
      strokeWidth="1.5px"
      y1="0.63"
      x2="36.18"
      y2="0.63"
    />
  </svg>
)

export default Hamburger
